<template>
  <div class="language_switcher">
    <div class="dropdown">
      <button
        class="btn dropdown-toggle shadow-none"
        type="button"
        id="languageSwitcherMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="d-flex flex-row justify-content-between align-items-center">
          <img
            src="@/assets/icons/language.svg"
            width="18"
            height="18"
            class="d-block"
          />
          <div class="ml-2">{{ currentLangLabel }}</div>
          <img
            src="@/assets/icons/switcher_arrow.svg"
            width="12"
            height="6.48"
            class="switcher_arrow d-block"
          />
        </div>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="languageSwitcherMenuButton"
      >
        <a
          class="dropdown-item d-flex flex-row align-items-center"
          v-for="lang in languages"
          :key="`lang_${lang.value}`"
          @click="changeLang(lang.value)"
        >
          <div>
            <img
              :src="require(`@/assets/icons/${lang.img}`)"
              width="24"
              height="24"
            />
          </div>
          <div class="switcher_label">{{ lang.label }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: () => ({
    languages: [
      { label: "Русский", value: "ru", img: "ru_lang.svg" },
      { label: "Кыргызча", value: "kg", img: "kg_lang.svg" },
    ],
  }),
  computed: {
    currentLangLabel() {
      return this.$i18n.locale.toUpperCase();
    },
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      document.title = this.$i18n.t("nav.search_number") + " :: Mega";
    },
  },
};
</script>

<style>
.language_switcher .dropdown-toggle::after {
  display: none;
}
.switcher_arrow {
  margin-left: 4px;
  transition: all 0.3s;
}
.language_switcher .show .switcher_arrow {
  transform: rotateZ(-180deg);
}
.switcher_label {
  margin-left: 24px;
}
</style>
