<template>
  <div class="mobile-layout">
    <div>
      <Navigation
        :name="$t('nav.search_number')"
        :to="'#close'"
        v-if="isMega24 == 1 && $route.name == 'NumberSearchMobile'"
      />
      <div class="mobile-layout-header">
        <div
          class="container d-flex flex-row justify-content-between align-items-center py-3"
        >
          <div>
            <a :href="`https://mega24.kg/${$i18n.locale}`">
              <img src="@/assets/logo.svg" width="160" height="16" />
            </a>
          </div>
          <div><LanguageSwitcher /></div>
        </div>
      </div>
    </div>
    <main class="LayoutMobile__main">
      <slot />
    </main>
    <div class="container mobile-footer">
      <div class="b-accordion">
        <ul>
          <li class="b-accordion__item">
            <div class="b-accordion__header">{{ $t("footer.desc_h") }}</div>
            <div class="b-accordion__content" v-html="$t('footer.desc')"></div>
          </li>
          <li class="b-accordion__item">
            <div class="b-accordion__header">{{ $t("footer.rules_h") }}</div>
            <div class="b-accordion__content" v-html="$t('footer.rules')"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import LanguageSwitcher from "../components/LanguageSwitcher.vue";
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: "mobile-layout",
  computed: {
    ...mapGetters(["isMega24"]),
  },
  created() {
    $(document).ready(function () {
      $(".b-accordion").each(function () {
        var $items = $(this).find(".b-accordion__item");
        var $headers = $(this).find(".b-accordion__header");
        var $contents = $(this).find(".b-accordion__content");
        var speed = 300;

        $items.each(function () {
          var findActive = false;

          if (!findActive) {
            if ($(this).hasClass("b-accordion__item_active")) {
              $(this).children(".b-accordion__content").show();
              return false;
            }
          } else {
            $(this).removeClass("b-accordion__item_active");
          }
        });

        $headers.click(function (event) {
          event.preventDefault();

          var $item = $(this).parent();

          if (!$item.hasClass("b-accordion__item_active")) {
            $items.removeClass("b-accordion__item_active");
            $item.addClass("b-accordion__item_active");
            $contents.slideUp(speed);
            $item.children(".b-accordion__content").slideDown(speed);
          } else {
            $item.children(".b-accordion__content").slideUp(speed);
            $item.removeClass("b-accordion__item_active");
          }
        });
      });
    });
  },
  components: {
    LanguageSwitcher,
    Navigation,
  },
};
</script>

<style>
.mobile-layout-header {
  box-shadow: 0px 4px 9px -3px rgba(142, 142, 142, 0.12);
}
.mobile-footer {
  margin-top: 40px;
  padding-bottom: 40px;
}
.b-accordion ul {
  margin: 0;
  padding: 0;
}

.b-accordion__item {
  margin-bottom: 4px;
  padding: 0;
  list-style: none;
}

.b-accordion__header {
  position: relative;
  padding: 15px 50px 15px 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 150ms all ease-in-out 0s;
  transition: 150ms all ease-in-out 0s;
  color: #3d3d3d;
  font-size: 16px;
  line-height: 18px;
  -webkit-touch-callout: none;
  border-bottom: 1px solid rgba(30, 30, 30, 0.05);
}

.b-accordion__header:after {
  position: absolute;
  top: 19px;
  right: 20px;
  content: "\e00b";
  -webkit-transition: 150ms all ease-in-out 0s;
  transition: 150ms all ease-in-out 0s;
  text-transform: none !important;
  color: #535353;
  font-family: "megacom" !important;
  font-size: 14px;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.b-accordion__content {
  display: none;
  padding: 20px 10px;
  font-size: 14px;
}

.b-accordion__content a {
  color: #000000;
  text-decoration: underline;
}

.b-accordion__header:hover,
.b-accordion__item_active .b-accordion__header {
  box-shadow: 0px 4px 9px -3px rgba(142, 142, 142, 0.12);
}

.b-accordion__item_active .b-accordion__header:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
