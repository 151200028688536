<template>
  <div class="main-page__nav">
    <div class="container">
      <router-link :to="to" :class="{ nav_link_arrow: showArrow }">{{
        name
      }}</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  props: ["to", "name"],
  computed: {
    ...mapGetters(["isMega24"]),
    showArrow() {
      if (this.$route.name == "NumberSearchMobile" && this.isMega24 == "0") {
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.main-page__nav {
  padding-top: 20px;
  /* background: #f5f5f5; */
  /* box-shadow: 0px 4px 9px -3px rgba(142, 142, 142, 0.12); */
}
.main-page__nav a {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.main-page__nav a:hover {
  color: inherit;
  text-decoration: none;
}
.nav_link_arrow:before {
  content: "";
  margin-right: 20px;
  background-image: url(../assets/icons/arrow_left.svg);
  background-size: 19px 16px;
  width: 19px;
  height: 16px;
  display: inline-block;
}
</style>