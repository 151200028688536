<template>
  <mobile-layout>
    <!-- <router-link to="/search-mobile">Back</router-link> -->
    <main class="main-page__body-m container">
      <div class="change_number_header d-flex flex-row">
        <div class="change_number_header_img">
          <img
            :src="require(`@/assets/icons/${category.img_alt}`)"
            width="48"
            height="48"
          />
        </div>
        <div class="align-self-center">
          <div class="change_number_value">
            {{ $t("change.number", [this.formattedMsisdn]) }}
          </div>
        </div>
      </div>
      <div class="change_number_info">
        <div class="change_number_info_row d-flex justify-content-between mt-2">
          <div class="change_number_info_label">{{ $t("info.category") }}</div>
          <div class="change_number_info_value">{{ $t(category.label_l) }}</div>
        </div>
        <div class="change_number_info_row d-flex justify-content-between mt-2">
          <div class="change_number_info_label">
            {{ $t("info.number_price") }}
          </div>
          <div class="change_number_info_value">
            {{ categoryPrice }}
            <span class="currency">с</span>
          </div>
        </div>
        <div
          class="change_number_info_row d-flex justify-content-between align-items-center mt-2"
          v-if="!restrictedClass"
        >
          <div class="change_number_info_label">
            <div>{{ $t("info.change_price") }}</div>
            <div class="change_number_info_label_help">
              {{ $t("info.change_price_help") }}
            </div>
          </div>
          <div class="change_number_info_value text-nowrap">
            {{ changePrice }} <span class="currency">с</span>
          </div>
        </div>
        <div class="change_number_info_row d-flex justify-content-between mt-2">
          <div class="change_number_info_label">{{ $t("info.discount") }}</div>
          <div class="change_number_info_value">
            {{ numberDiscount }}
          </div>
        </div>
      </div>
      <div class="change_number_total_sum">
        {{ $t("info.total_price", [totalPrice]) }}
        <span class="currency">с</span>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-accept-filter btn-block"
          @click="routeToChangeNumber()"
        >
          {{ $t("change.btn") }}
        </button>
        <button
          type="button"
          class="btn btn-reserve btn-block mt-3"
          @click="routeToReserveNumber()"
          v-if="!restrictedClass"
        >
          {{ $t("reserve.btn") }}
        </button>
      </div>
      <div class="order_sim_card_wrapper d-flex flex-row align-items-end">
        <div><img src="@/assets/icons/sim.svg" width="41" height="32" /></div>
        <div>
          <a
            class="order_sim_card"
            target="_blank"
            :href="$t('info.order_sim_link')"
            >{{ $t("info.order_sim") }}</a
          >
        </div>
      </div>
    </main>
  </mobile-layout>
</template>

<script>
import MobileLayout from "@/layouts/MobileLayout";
import helperMixin from "@/mixins/helper.mixin";
import prices from "@/utils/prices";
import { mapGetters } from "vuex";

export default {
  name: "NumberInfoMobile",
  mixins: [helperMixin],
  props: ["msisdn", "categoryId"],
  data: () => ({
    changePrice: prices.CHANGE,
    reservePrice: prices.RESERVE,
    info: {},
  }),
  computed: {
    ...mapGetters(["msisdnInfo", "numberClass"]),
    formattedMsisdn() {
      return this.msisdn.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
    },
    category() {
      let categoryId = this.categoryId;
      if (categoryId == undefined){
        if(this?.info?.NCLS_ID === undefined) {
          window.location.href = '/';
        }
        categoryId = Number(this.info.NCLS_ID.substring(0, 1));
      }
      return this.numberClass(categoryId);
    },
    categoryPrice() {
      let price = this.info.CATEGORY_PRICE;
      return this.formatPrice(price);
    },
    numberDiscount() {
      let discount = this.info.MSISDN_DISCOUNT;
      return `${discount}%`;
    },
    totalPrice() {
      let price = parseInt(this.info.CATEGORY_PRICE);
      let discount = parseInt(this.info.MSISDN_DISCOUNT) / 100;
      let totalValue = price - price * discount + this.changePrice;

      if (this.restrictedClass) return this.formatPrice(price);

      return this.formatPrice(totalValue);
    },
    restrictedClass() {
      const restrictedClasses = ["48", "47", "46", "45"];
      let NCLS_ID = this.info.NCLS_ID;
      if (restrictedClasses.includes(NCLS_ID)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    routeToChangeNumber() {
      const data = {
        msisdn: this.msisdn,
        category: this.category,
        total_sum: this.totalPrice,
      };
      if (this.restrictedClass) {
        data.total_sum = this.formatPrice(this.info.CATEGORY_PRICE);
        this.$router.push({
          name: "RestricredActionsMobile",
          params: { info: data },
        });
      } else {
        this.$router.push({
          name: "NumberChangeMobile",
          params: { info: data },
        });
      }
    },
    routeToReserveNumber() {
      const data = {
        msisdn: this.msisdn,
        category: this.category,
        total_sum: this.reservePrice,
      };
      this.$router.push({
        name: "NumberReserveMobile",
        params: { info: data },
      });
    },
  },
  created() {
    this.info = this.msisdnInfo(this.msisdn);
  },
  components: {
    MobileLayout,
  },
};
</script>

<style>
.change_number_info_row {
  padding-top: 8px;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(30, 30, 30, 0.05);
}
.change_number_info_label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e1e1e;
}
.change_number_info_label_help {
  width: 75%;
  margin-top: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.change_number_info_value {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e1e1e;
}
.change_number_total_sum {
  margin: 24px 0;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.order_sim_card_wrapper {
  margin-top: 24px;
}
.order_sim_card {
  display: block;
  margin-left: 8px;
  text-decoration: underline;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.order_sim_card:hover {
  color: #000000;
}
</style>
