export default {
    data() {
        return {};
    },
    methods: {
        formatPrice(price) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        replaceByMask(maskValue, result) {
            if (maskValue == 1) {
                result = result.replace(/(.{4})(.{3})(.{3})/, '$1 $2 $3');
            } else if (maskValue == 2) {
                result = result.replace(/(.{4})(.{2})(.{2})(.{2})/, '$1 $2 $3 $4');
            } else {
                result = result.replace(/(.{4})(.{6})/, '$1 $2');
            }
            return result;
        },
        formatMsisdnByMask(msisdn, maskValue, searchType, optNumber) {
            let result = msisdn.replace("996", "0");

            if (searchType == "favnum") {
                result = this.replaceByMask(maskValue, result);
                const optNumberSplitted = optNumber.split('');
                let optNumberRe = "";
                optNumberSplitted.forEach((element, index, array) => {
                    if (index === (array.length - 1)) {
                        optNumberRe += element;
                    } else {
                        optNumberRe += element + "[\\s]*";
                    }
                })
                const re = new RegExp(optNumberRe, "ig");

                return result.replace(re, (matchedText) => `<span class="highlight_number">${matchedText}</span>`);
            } else {
                result = this.replaceByMask(maskValue, result);
                let optNumberMasked = this.replaceByMask(maskValue, `0555${optNumber}`);
                const onlyCode = result.substring(0, 4);
                const onlyNumber = result.substring(4);
                const optNumberSplitted = optNumberMasked.substring(4).split('');

                let newNumber = "";
                optNumberSplitted.forEach((element, index) => {
                    if (element == onlyNumber[index] && element != " ") {
                        newNumber += `<span class="highlight_number">${element}</span>`
                    } else {
                        newNumber += onlyNumber[index]
                    }
                });

                result = `${onlyCode} ${newNumber}`;


            }

            return result
        }
    },
};